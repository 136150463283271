import React, { useState, useEffect, useMemo } from 'react';
import './style.scss';
import { NotificationService } from '../../../services/notificationService';
import { sessionManager } from '../../../sessionManager';
import { NotificationItem } from './NotificationItem';
import { useTranslation } from 'react-i18next';

export const Notifications = ({recordsToShow}) => {
    const notificationServiceClient = useMemo(() => new NotificationService(),[]);
    const manager = new sessionManager();
    const userLoggedIn = JSON.parse(manager.getUserSession());
    const [notifications, setNotifications] = useState([]);
    const [totalNotifications, setTotalNotifications] = useState(0);
    const { t } = useTranslation(['menu']);

    useEffect(() => {
        const fetchData = async () => {
            const notificationsResponse = await notificationServiceClient.getNotificationsByUserId(userLoggedIn.userId);

            if (notificationsResponse.success) {
                setTotalNotifications(notificationsResponse.value.length);
                setNotifications(notificationsResponse.value.slice(0, recordsToShow));
            }
        };
        fetchData();

    }, [userLoggedIn.userId, notificationServiceClient, recordsToShow]);

    const handleClick = () => {
        window.location.href = `/my-notifications`;
    }

    return (
        <>
            <h4>{t('notifications.title')}</h4>
            <div className='notifications-section'>
                {notifications.map((notification) => (
                    <NotificationItem key={notification.rowNumber} notification={notification}></NotificationItem>
                ))}
                {totalNotifications > recordsToShow && (
                <div className="notification-card">
                    <button className="notification-show-more" onClick={() => handleClick()}>
                        {t('notifications.seeMoreButton')}
                    </button>
                </div>
                )}
            </div>
        </>
    )
}